@tailwind base;
@tailwind components;
@tailwind utilities;

*,
body {
  font-family: "Inter", sans-serif;
}

@layer base {
  th {
    @apply font-normal text-left /*reset*/;
  }
  button,
  a,
  tr,
  td,
  input {
    @apply transition-all duration-200;
  }
  .sidebar::-webkit-scrollbar {
    width: 10px;
  }
  .sidebar::-webkit-scrollbar-track {
    box-shadow: 0 0 2px #ddd;
    border-radius: 10px;
  }
  .sidebar::-webkit-scrollbar-thumb {
    background-color: #aaa5;
    border-radius: 10px;
  }
}

@layer components {
  .input__login {
    @apply py-3 px-4 w-full outline-none ring-2 transition-all ring-zinc-900 font-mono focus:ring-zinc-200 bg-zinc-900 rounded-sm placeholder:text-zinc-500;
  }
  .sections__default {
    @apply min-h-screen lg:min-h-full w-full bg-zinc-100 dark:bg-[#212329] dark:text-zinc-50 pb-20 pt-14 md:pb-0 md:pt-0;
  }
  .dashboard__card {
    @apply flex flex-col text-zinc-500/80 text-center sm:text-left dark:text-zinc-400 border  bg-white dark:bg-white/10 w-full dark:border-zinc-900/50 rounded-md shadow-sm shadow-zinc-200 dark:shadow-white/5;
  }
  .dashboard__svg {
    @apply w-5 h-5 hidden sm:block;
  }
  .dashboard__tbody--tr {
    @apply text-sm text-zinc-700 dark:text-zinc-200 ring-2 ring-inset ring-transparent hover:ring-zinc-300 dark:hover:ring-zinc-500 odd:bg-white even:bg-zinc-100 dark:odd:bg-transparent dark:even:bg-zinc-600 transition-all;
  }
  .main__table--th {
    @apply p-2 lg:p-3 text-xs border-b bg-zinc-200 dark:bg-zinc-900 border-zinc-200 dark:border-zinc-800 font-semibold text-zinc-600 dark:text-zinc-400 uppercase;
  }
  .main__table--td {
    @apply border-zinc-200 px-3 py-2 text-xs;
  }
  .permission__checkbox--area {
    @apply flex items-center relative text-right;
  }
  .checked__icon {
    @apply absolute left-1.5 text-white transform scale-0 peer-checked:scale-100;
  }
  .permission__label {
    @apply border border-slate-300 dark:border-zinc-600 cursor-pointer px-4 rounded-md text-slate-700 dark:text-slate-200 text-xs 2xl:text-sm;
  }
  .checked__label {
    @apply border-green-600 bg-green-600 text-slate-50;
  }
  .header__title {
    @apply text-sm sm:text-base 2xl:text-xl text-zinc-600 dark:text-zinc-400;
  }
  .main__title {
    @apply text-sm lg:text-base text-zinc-600 dark:text-zinc-200;
  }
  .modal__area--fixed {
    @apply fixed inset-0 z-[200]  bg-zinc-900/50 dark:bg-zinc-900/60 flex justify-center transition-all overflow-y-auto;
  }
  .modal__area {
    @apply overflow-y-auto 2xl:overflow-y-visible bg-zinc-50 dark:bg-[#212329] border-2 border-zinc-200 dark:border-zinc-800 shadow-md shadow-zinc-900/20 dark:shadow-zinc-900 p-5 lg:rounded-2xl w-full sm:w-auto lg:max-w-md h-max max-h-max lg:mt-[10vh];
  }
  .modal__area--small {
    @apply overflow-y-auto bg-zinc-50 dark:bg-[#212329] border-2 border-zinc-200 dark:border-zinc-800 shadow-md shadow-zinc-900/20 dark:shadow-zinc-900 p-5 lg:rounded-2xl w-full sm:w-auto lg:max-w-md h-max max-h-max lg:mt-[10vh];
  }
  .default__btn--blue {
    @apply bg-sky-600 flex dark:bg-sky-700/80 shadow-sm transition-all ring-2 ring-inset ring-transparent shadow-sky-500 text-xs 2xl:text-sm text-zinc-100 rounded-md py-1 px-5 text-center hover:bg-sky-500 dark:hover:bg-sky-600 font-medium hover:shadow-none;
  }
  .pabx__server {
    @apply border border-zinc-200 bg-white opacity-90 dark:border-transparent text-left rounded-lg p-4 font-medium shadow-md hover:bg-white dark:bg-zinc-700/70 shadow-zinc-800/10 dark:shadow-zinc-900/95 hover:border-emerald-400/70 hover:shadow-none dark:hover:border-emerald-400/30 max-w-md w-full cursor-pointer;
  }
  .edit__button {
    @apply bg-white dark:bg-transparent hover:bg-zinc-200 dark:hover:bg-zinc-900 border border-zinc-200 dark:border-zinc-600 text-zinc-700 dark:text-zinc-200 hover:text-zinc-900 text-xs w-max py-1 px-2 rounded-sm 2xl:rounded-md;
  }
  .safe__status {
    @apply bg-emerald-400 text-white p-1;
  }
  .warning__status {
    @apply text-amber-500;
  }
  .error__status {
    @apply text-red-500;
  }
}
